<template>
    <div>
        <div class="phoneGame" v-show="landscape" :style="gameHeight">
            <div id="game-container" ref="gameContainer"></div>
            <NewCloudGameLauncher
                    ref="launchGame"
                    @startCloudGame="startCloudGame"
                    @changeDialogStatus="changeDialogStatus"
                    @stopGamePageAnimation="stopGamePageAnimation"
                    :dialogStatus="dialogStatus"
                    :mobileGameRotateContainer="mobileGameRotateContainer"
            ></NewCloudGameLauncher>
            <div :class="['game_setting', mobileGameRotateContainer ? 'mobileGame' :'' ]" v-if="gameState !=='game-closed'">
                <img src="../../assets/images/game/phoneGame-setting.png" @click.stop="()=>{settingShow=!settingShow}">
                <ul v-if="settingShow">
                    <li>
                        <i></i>
                        <p v-if="rtt<=400" style="color: #6DD400">{{rtt}}ms</p>
                        <p v-else style="color: red">{{rtt}}ms</p>
                    </li>
                    <li v-show="supportRecharge && settingRecharge" @click="recharge()">
                        <i></i>
                        <p>充值</p>
                    </li>
                    <li @click="()=>{$store.commit('GAME_INPUT',!$store.state.game.game_input)}" v-show="gameState !== 'game-closed' && gameSuccess && game_id != '903'">
                        <i></i>
                        <p>输入</p>
                    </li>
                    <li @click="archive" v-show="getArchive && gameState !=='game-closed'">
                        <i></i>
                        <p>存档</p>
                    </li>
                    <li @click.stop="()=>{voiceSlider=!voiceSlider}" style="display: none">
                        <i></i>
                        <p>声音</p>
                        <div v-if="voiceSlider" class="game-voice" @click.stop="()=>{}">
                            <el-slider v-model="voiceStatus" :show-tooltip="false" vertical height="100px" @input="changeVoiceStatus"></el-slider>
                        </div>
                    </li>
                    <li @click="close_game" v-show="gameState !=='game-closed'">
                        <i></i>
                        <p>退出</p>
                    </li>
                </ul>
            </div>
            <div class="game_input" v-if="$store.state.game.game_input && gameState !=='game-closed'">
                <input type="text" v-model="gameInputMsg" v-focus @input="inputText"></input>
                <button @click="inputSure">确认</button>
            </div>
            <template v-if="game_id != '903'">
                <div class="game_btn" v-for="(item,index) in keyData" :key="index"
                     :style="{
                        width: brownWidth*item.width+'px',
                        height: brownWidth*item.height+'px',
                        lineHeight: brownWidth*item.height+1+'px',
                        top: item.y*100 + '%',
                        left: item.x*100 + '%',
                        fontSize: item.textSize + 'px'
                        }"
                     @click="operateRecharge(item)"
                     @touchstart="operate(item)"
                     @touchend="operateEnd(item)"
                >
                    <template v-if="item.type == 'image_view_M' && item.id == 1">
                        <img v-if="mouseLeft" src="../../assets/images/game/game-lefth.png">
                        <img v-else src="../../assets/images/game/game-left.png">
                    </template>
                    <template v-if="item.type == 'image_view_M' && item.id == 2">
                        <img v-if="!mouseLeft" src="../../assets/images/game/game-rightbtnh.png">
                        <img v-else src="../../assets/images/game/game-rightbtn.png">
                    </template>
                    <template v-if="item.type == 'button_view_S' && item.text == 'Alt'">
                        <p v-if="altStatus" class="game_btnHover">{{item.text}}</p>
                        <p v-else>{{item.text}}</p>
                    </template>
                    <template v-if="item.type == 'button_view_S' && item.text == 'Ctrl'">
                        <p v-if="ctrlStatus" class="game_btnHover">{{item.text}}</p>
                        <p v-else>{{item.text}}</p>
                    </template>
                    <template v-if="item.type == 'button_view_S' && item.text == 'Shift'">
                        <p v-if="shiftStatus" class="game_btnHover">{{item.text}}</p>
                        <p v-else>{{item.text}}</p>
                    </template>
                    <template v-if="item.type == 'image_view_N'">
                        <img v-if="!btnIsShow" src="../../assets/images/game/game-btnHide.png">
                        <img v-else src="../../assets/images/game/game-btnShow.png">
                    </template>
                    <p v-if="item.type == 'button_view_F'">{{item.text}}</p>
                    <p v-if="item.type == 'button_view_N'">{{item.text}}</p>
                    <p v-if="item.type == 'button_view_C'">{{item.text}}</p>
                </div>
            </template>
            <Joystick v-if="gameSuccess && btnIsShow && !isTencentMobileGame && game_id != '903'" :options="options" @move="move($event)"></Joystick>
            <div v-if="tipsShow && gameSuccess" @click="()=>{tipsShow=!tipsShow}" class="game_float">
                <img src="../../assets/images/game/game-sreen.png" alt="">
                <p>点击屏幕，立即体验</p>
            </div>
        </div>
        <div class="title" v-show="!landscape">
            <img src="../../assets/images/game/game-phone.png" alt="">
            <p>为了更好的体验，请使用横屏畅玩</p>
        </div>
    </div>
</template>

<script>
    import helper from "@/utils/helper";
    import gameCommonParam from '@/mixins/gameCommonParam';

    export default {
        name: "PhoneGame",
        mixins: [gameCommonParam],
        data(){
            return{
                tipsShow: false,
                gameHeight: 0,
                voiceSlider: false,
                settingShow: false,
                mouseLeft: true, // 鼠标左键
                altStatus: false, // alt
                ctrlStatus: false, // ctrl
                shiftStatus: false, // shift
                btnIsShow: true,
                brownWidth: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
                keyData: [], // 虚拟按键信息
                type_id: '',
                type_type: '',
                options: {
                    // size: 100,
                },
                feedDialog: false, // 反馈弹窗
                qualityInfo: false, // 画质选择显示
                voiceStatus: 100, // 游戏声音状态
                game_id: '', // 当前游戏id
                gameName: '',
                wsToken: '',
                cloudApp: null,
                _onnine: null,
                network_info: '', // 链接信息
                rtt: '-', // 延迟
                bitrate: '-', // 码率
                session_id: '', // sessionId
                _ws_connection: null,
                daoyuAppId: 199, // 叨鱼端使用
                msgTitle: '',
                msgWarningClass: '',
                isSdo: false,
                copyHttp: document.URL.indexOf("/phonegame") >= 0 ? document.URL.replace('/phonegame', '/game') : document.URL, // 网页地址
                settingRecharge: true, // 设置栏充值按钮是否显示
                joystickStatus: '', // 游戏页面变化时，防止摇杆发生偏移重新绘图摇杆
                timer: '', //
            }
        },
        created() {
            this.game_id = this.$route.params.id;
            if (this.$route.name == 'Sdo') {
                this.isSdo = true;
            }
        },
        watch:{
            gameBtn(state){
                this.keyData = state
                if (helper.isWeiXin()) {
                    let data = state.map((item)=>{
                        if (item.type == 'image_view_N') {
                            item.y = 0.02
                            item.x = 0.07
                        }
                        return item
                    })
                    this.keyData = data
                    helper.isIphone() ? this.tipsShow = true : ''
                } else {
                    let data = state.filter((item)=>{
                        return item.modelType == "button_recharge";
                    })
                    data.length==0 ? this.settingRecharge = true : this.settingRecharge = false
                    this.keyData = state
                }
            },
            gameIcon(state) {
                let link = document.createElement("link");
                link.rel = "apple-touch-icon-precomposed";
                let iconHttp = state
                link.href = iconHttp;
                document.head.appendChild(link);
            },
            gameName(state){
                document.title = state
            },
            joystickStatus() {
                clearTimeout(this.timer)
                this.btnIsShow = false
                this.timer = setTimeout(()=>{
                    clearTimeout(this.timer)
                    this.btnIsShow = true
                },20)
            },
            // 腾讯手游 改变样式
            isTencentMobileGame(state) {
                if(state){
                    this.gameHeight ='100% !important;width:100%';
                }
            }
        },
        mounted() {
            // 云游戏不支持IE浏览器/弹框提示
            this.onWindowResized()
            this.gameReady()
            window.addEventListener('resize', this.onWindowResized);
            window.addEventListener("orientationchange", this.onScreenResized);
            window.addEventListener('beforeunload',()=>{sessionStorage.removeItem('count')})
        },
        computed: {
            // 获取当前的游戏虚拟按键
            gameBtn: function () {
                let data = []
                data = this.$store.state.game.game_keyBtn.filter((item)=>{
                    return item.type != 'rocker_view'
                })
                return data;
            },
            gameIcon: function () {
                if (this.$store.state.game.playing_game) {
                    return this.$store.state.game.playing_game.game_pic ? this.$store.state.game.playing_game.game_pic : this.$store.state.baseUrl + '/favicon_desktop.png'
                }
                return this.$store.state.baseUrl + '/favicon_desktop.png';
            }
        },
        methods: {
            changeVoiceStatus() {
                TCGSDK.setVideoVolume(this.voiceStatus/100)
            },
            onScreenResized() {
                if (window.orientation == 90 || window.orientation == -90) {//横屏
                    if (sessionStorage.getItem('count') == null) {
                        location.reload();
                    }
                    return false;
                } else if (window.orientation == 0 || window.orientation == 180) {//竖屏
                    sessionStorage.setItem('count',true);
                    return false;
                }
            },
            operateEnd(item) {
                if (item.type == 'button_view_C') {
                    let keyGroup = item.groupIds.map((i)=>{
                        return { type: "keyboard",key: i,down: false }
                    })
                    TCGSDK.sendSeqRawEvents(keyGroup)
                    return false
                }
                if (item.type == 'button_view_F' || item.type == 'button_view_N') {
                    if (item.id == 1) {
                        TCGSDK.sendRawEvent({ type: "mouseleft",down: false });
                        return false
                    }
                    if (item.id == 2) {
                        TCGSDK.sendRawEvent({ type: "mouseright",down: false });
                        return false
                    }
                    TCGSDK.sendRawEvent({ type: "keyboard",key: item.id,down: false });
                }
            },
            operate (item) {
                if (item.type == 'image_view_M') {
                    item.id == 1 ? this.mouseLeft = true : this.mouseLeft = false
                } else if (item.type == 'button_view_S') {
                    switch (item.id) {
                        case 18:
                            this.ctrlStatus = false;
                            this.shiftStatus = false;
                            this.altStatus = !this.altStatus;
                            TCGSDK.sendRawEvent({ type: "keyboard",key: 16,down: false });
                            TCGSDK.sendRawEvent({ type: "keyboard",key: 17,down: false });
                            TCGSDK.sendRawEvent({ type: "keyboard",key: 18,down: this.altStatus });
                            break;
                        case 17:
                            this.altStatus = false;
                            this.shiftStatus = false;
                            this.ctrlStatus = !this.ctrlStatus;
                            TCGSDK.sendRawEvent({ type: "keyboard",key: 16,down: false });
                            TCGSDK.sendRawEvent({ type: "keyboard",key: 18,down: false });
                            TCGSDK.sendRawEvent({ type: "keyboard",key: 17,down: this.ctrlStatus });
                            break;
                        case 16:
                            this.ctrlStatus = false;
                            this.altStatus = false;
                            this.shiftStatus = !this.shiftStatus;
                            TCGSDK.sendRawEvent({ type: "keyboard",key: 17,down: false });
                            TCGSDK.sendRawEvent({ type: "keyboard",key: 18,down: false });
                            TCGSDK.sendRawEvent({ type: "keyboard",key: 16,down: this.shiftStatus });
                            break;
                    }
                } else if (item.type == 'button_view_C') {
                    let keyGroup = item.groupIds.map((i)=>{
                        return { type: "keyboard",key: i,down: true }
                    })
                    // console.log(keyGroup.flat())
                    TCGSDK.sendSeqRawEvents(keyGroup)
                    return false
                } else {
                    if (item.id == 1) {
                        TCGSDK.sendRawEvent({ type: "mouseleft",down: true });
                        return false
                    }
                    if (item.id == 2) {
                        TCGSDK.sendRawEvent({ type: "mouseright",down: true });
                        return false
                    }
                    if (item.modelType == 'button_recharge') {
                        return false
                    }
                    if (item.modelType == 'button_mouse_middle_up') {
                        TCGSDK.sendRawEvent({ type: "mousescroll",delta: -1 });
                        return false
                    }
                    if (item.modelType == 'button_mouse_middle_down') {
                        TCGSDK.sendRawEvent({ type: "mousescroll",delta: +1 });
                        return false
                    }
                    if (item.modelType == 'button_display_input_keyboard') {
                        let status = this.$store.state.game.game_input
                        this.$store.commit('GAME_INPUT',!status)
                    }
                    if (item.id == 1000) {
                        if (this.btnIsShow) {
                            this.keyData = this.keyData.filter((i)=>{
                                return i.type == 'image_view_N'
                            })
                        } else {
                            this.keyData = this.$store.state.game.game_keyBtn.filter((item)=>{
                                return item.type != 'rocker_view'
                            })
                        }
                        this.btnIsShow = !this.btnIsShow
                    }
                    TCGSDK.sendRawEvent({ type: "keyboard",key: item.id,down: true });
                }
            },
            operateRecharge (item) {
                if (item.modelType == 'button_recharge') {
                    this.recharge();
                } else {
                    return false
                }
            },
            move (e) {
                let data = this.$store.state.game.game_keyBtn.filter((item)=>{
                    return item.type == 'rocker_view'
                })

                let width = this.$refs.gameContainer.offsetWidth
                let height = this.$refs.gameContainer.offsetHeight
                const {moveForward,moveBackward,moveRight,moveLeft} = e


                if (data[0].modelType == 'rocker_mouse_click') {// 传奇归来（左键走路右键奔跑）
                    let pageX = parseInt(width*data[0].characterX)
                    let pageY = parseInt(height*data[0].characterY)
                    if (moveForward) pageY -= 50
                    if (moveBackward) pageY += 50
                    if (moveRight) pageX += 50
                    if (moveLeft) pageX -= 50
                    TCGSDK.mouseMove(0, 'touchstart', pageX, pageY);
                } else if (data[0].modelType == 'rocker_keyboard') {// 龙之谷（WASD控制移动）
                    let keyCode = []
                    if (moveForward) keyCode=keyCode.concat(...[87])
                    if (moveBackward) keyCode=keyCode.concat(...[83])
                    if (moveRight) keyCode=keyCode.concat(...[68])
                    if (moveLeft) keyCode=keyCode.concat(...[65])
                    this.$store.commit('GAME_VIRBTN', keyCode)
                } else if (data[0].modelType == 'rocker_mouse_click_left'){// 预言（鼠标左键控制移动）
                    let pageX = parseInt(width*0.5)
                    let pageY = parseInt(height*0.5)
                    if (moveForward) pageY -= 60
                    if (moveBackward) pageY += 60
                    if (moveRight) pageX += 60
                    if (moveLeft) pageX -= 60
                    TCGSDK.mouseMove(0, 'touchstart', pageX, pageY);
                } else {
                    let keyCode = []
                    if (moveForward) keyCode=keyCode.concat(...[87])
                    if (moveBackward) keyCode=keyCode.concat(...[83])
                    if (moveRight) keyCode=keyCode.concat(...[68])
                    if (moveLeft) keyCode=keyCode.concat(...[65])
                    this.$store.commit('GAME_VIRBTN', keyCode)
                }
            },
            // 初始化页面参数
            initPageParams(res) {
                let resultCode = res.code;
                let wsServer = '';
                if (resultCode == 4050) {
                    this.$router.push({
                        name: 'NoSupport',
                        params: {
                            gameId:  this.game_id,
                            copyHttp: this.copyHttp
                        }
                    })
                    return;
                }
                // 叨鱼在我们平台登录失效
                if (resultCode == 40001) {
                    this.onWindowResized();
                    this.skipDaoyuLoginPage();
                    return;
                }
                if (res.status) {
                    this.gameName = res.data.game_params.name;
                    this.wsToken = res.data.ws_token.token;
                    wsServer = res.data.ws_token.ws_server;
                }
                this.landscape ? this.$refs.launchGame.requestStartCloudGame('', this.wsToken, wsServer) : ''
            },
            // 打开充值界面
            recharge () {
                if(helper.isWeiXin()) {
                    this.$store.commit('MESSAGE_TEXT','微信内置浏览器暂不支持充值')
                } else {
                    this.openRechargeLink();
                }
            },
            // 浏览器窗口变化
            onWindowResized(){
                    let browserWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                    let browserHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

                    this.gameHeight = 'height:'+ browserHeight + 'px !important';
                    if (this.isTencentMobileGame) {
                        this.gameHeight ='100% !important;width:100%';
                    }
                    browserWidth < browserHeight ? this.landscape = false : (this.landscape = true,this.joystickStatus= Date.now())
            }
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onWindowResized);
            window.removeEventListener("orientationchange", this.onScreenResized);
            window.removeEventListener('beforeunload',()=>{sessionStorage.removeItem('count')})
        }
    }
</script>

<style scoped lang="scss">

    .phoneGame {
        width: 100vw;
        height: 100%;
        background: rgba(0,0,0,0.5);
        box-sizing: border-box;
        position: fixed;
        top: 0;left: 0;
        touch-action: none;
        .game_btn {
            box-sizing: border-box;
            position: absolute;
            z-index: 9;
            text-align: center;
            border-radius: 50%;
            -webkit-touch-callout:none;
            -webkit-user-select:none;
            -khtml-user-select:none;
            -moz-user-select:none;
            -ms-user-select:none;
            user-select:none;
            p {
                background: url("../../assets/images/game/game-vir.png") no-repeat center;
                background-size: cover;
                color: #FFFFFF;
            }
            .game_btnHover {
                background: url("../../assets/images/game/game-virh.png") no-repeat center;
                background-size: cover;
                color: #D69D4D;
            }
            img {width: 100%;height: 100%}
        }
        .game_btn:active p{
            background: url("../../assets/images/game/game-virh.png") no-repeat center;
            background-size: cover;
            color: #D69D4D;
        }
        .game_setting {
            height: 11%;
            position: absolute;
            top: 5px;
            left: 15px;
            img {
                position: absolute;
                z-index: 9;
                height: 100%;
            }
            ul {
                height: 100%;
                position: absolute;
                top: 0; left: 0;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                background: rgba(0, 0, 0, 0.5);
                border-radius: 17px;
                li {
                    width: 30px;
                    height: 100%;
                    margin-right: 10px;
                    font-size: 12px;
                    color: #ffffff;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    i {
                        display: block;
                        width: 18px;
                        height: 18px;
                    }
                    p {
                        height: 0px;
                        text-align: center;
                    }
                }
                li:nth-child(1) {
                    margin-left: 10px;
                    i {
                        width: 18px;
                        height: 12px;
                        background: url("../../assets/images/game/game-ping.png") no-repeat top center;
                        background-size: cover;
                        margin-bottom: 5px;
                    }
                }
                li:nth-child(5) {
                    position: relative;
                    i {
                        background: url("../../assets/images/game/pgame-voice.png") no-repeat top center;
                        background-size: cover;
                    }
                    .game-voice {
                        width: 30px;
                        background: rgba(0, 0, 0, 0.5);
                        position: absolute;
                        top: 33px;left: 0;
                        border-radius: 15px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 0;
                        /deep/ .el-slider__bar {
                            background-color: #D5A561;
                        }
                        /deep/ .el-slider__button {
                            border-color: #FFFFFF;
                        }
                        /deep/ .el-slider__runway {
                            background-color: #ADADB0;
                        }
                        span {
                            display: block;
                            width: 20px;
                            height: 49px;
                            font-size: 16px;
                            font-weight: 400;
                            color: #FFFFFF;
                            text-align: center;
                            line-height: 49px;
                            margin-left: 15px;
                        }
                    }
                }
                li:nth-child(2) {
                    i {
                        background: url("../../assets/images/game/pgame-recharge.png") no-repeat top center;
                        background-size: cover;
                    }
                }
                li:nth-child(3) {
                    i {
                        background: url("../../assets/images/game/pgame-input.png") no-repeat top center;
                        background-size: cover;
                    }
                }
                li:nth-child(4) {
                    i {
                        background: url("../../assets/images/game/pgame-storage.png") no-repeat top center;
                        background-size: cover;
                    }
                }
                li:nth-child(6) {
                    margin-right: 20px;
                    i {
                        background: url("../../assets/images/game/pgame-exit.png") no-repeat top center;
                        background-size: cover;
                    }
                }
            }
        }
        .game_setting.mobileGame{
            top: 0px;
            height: 6%;
            right: 7%;
            left: unset;
            transform:rotate(90deg);
            -ms-transform:rotate(90deg); 	/* IE 9 */
            -moz-transform:rotate(90deg); 	/* Firefox */
            -webkit-transform:rotate(90deg); /* Safari 和 Chrome */
            -o-transform:rotate(deg);
        }
        .game_input {
            width: 340px;
            height: 12%;
            position: absolute;
            top: 50px;left: 50%;
            z-index: 9;
            transform: translateX(-50%);
            background: linear-gradient(to right,#FAD8A1,#E3AF6D);
            padding: 0 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            input {
                background: rgba(255,255,255,0.8);
                width: 264px;
                height: 85%;
                border-radius: 5px 0 0 5px;
                padding-left: 10px;
            }
            button {
                width: 72px;
                height: 85%;
                background: linear-gradient(154deg, #FAD8A1 0%, #E3AF6D 100%);
                border: none;
                outline: none;
                border-radius: 0 5px 5px 0;
            }
        }
        .game_float {
            width: 100%;
            height: 100%;
            background: #2F333D;
            position: fixed;
            top: 0;
            z-index: 10;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
                height: 95%;
                background-size: cover;
            }
            p {
                height: 20px;
                line-height: 20px;
                position: absolute;
                bottom: 20px;
                font-size: 12px;
                color: #FFFAF4;
            }
        }
    }
    .title {
        width: 100vw;
        height: 100vh;
        background: #2F333D;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
            width: 30vw;
            height: 30vw;
            margin-bottom: 35px;
        }
        p {
            font-size: 20px;
            color: #FFFAF4;
        }
    }
</style>
